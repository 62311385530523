import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';
import edit from '../assets/svg/edit.svg';

import home from '../assets/svg/home.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>

                </div>

                <div className="items">
                    <h6>POČETNA</h6>
                    <ul>

                        <li>
                            <Link to='/' className={this.props[0].location.pathname == '/' ? 'active' : null}>
                                <Isvg src={home} />
                                Uredi
                            </Link>
                        </li>




                    </ul>
                    <h6>O NAMA</h6>
                    <ul>

                        <li>
                            <Link to='/aboutus' className={this.props[0].location.pathname == '/aboutus' ? 'active' : null}>
                                <Isvg src={edit} />
                                Uredi
                            </Link>
                        </li>




                    </ul>

                    <h6>USLUGE</h6>
                    <ul>

                        <li>
                            <Link to='/collection/services' className={this.props[0].location.pathname == '/collection/services' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve usluge
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/services/new' className={this.props[0].location.pathname == '/collection/services/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Nova usluga
                            </Link>
                        </li>


                    </ul>

                    <h6>REFERENCE</h6>
                    <ul>

                        <li>
                            <Link to='/collection/projects' className={this.props[0].location.pathname == '/collection/projects' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve reference
                            </Link>
                        </li>

                        <li>
                            <Link to='/projects' className={this.props[0].location.pathname == '/projects' ? 'active' : null}>
                                <Isvg src={list} />
                                Sort referenci
                            </Link>
                        </li>

                        <li>
                            <Link to='/collection/projects/new' className={this.props[0].location.pathname == '/collection/projects/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Nova referenca
                            </Link>
                        </li>


                    </ul>

                    <h6>KATEGORIJE REFERENCI</h6>
                    <ul>

                        <li>
                            <Link to='/collection/projectsCategories' className={this.props[0].location.pathname == '/collection/projectsCategories' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve kategorije
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/projectsCategories/new' className={this.props[0].location.pathname == '/collection/projectsCategories/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Nova kategorija
                            </Link>
                        </li>


                    </ul>



                    <h6>BLOG</h6>
                    <ul>

                        <li>
                            <Link to='/collection/blog' className={this.props[0].location.pathname == '/collection/blog' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi blogovi
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/blog/new' className={this.props[0].location.pathname == '/collection/blog/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Novi blog
                            </Link>
                        </li>


                    </ul>
                    <h6>KATEGORIJE BLOGA</h6>
                    <ul>

                        <li>
                            <Link to='/collection/blogCategories' className={this.props[0].location.pathname == '/collection/blogCategories' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve kategorije
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/blogCategories/new' className={this.props[0].location.pathname == '/collection/blogCategories/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Nova kategorija
                            </Link>
                        </li>


                    </ul>





                    <h6>KONTAKTI</h6>
                    <ul>
                        <li>
                            <Link to='/contacts' className={this.props[0].location.pathname == '/contacts' ? 'active' : null}>
                                <Isvg src={mail} />
                                Svi kontakti
                            </Link>
                        </li>

                    </ul>


                    <h6>SEO</h6>
                    <ul>
                        <li>
                            <Link to='/collection/seo' className={this.props[0].location.pathname == '/collection/seo' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi linkovi
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/seo/new' className={this.props[0].location.pathname == '/collection/seo/new' ? 'active' : null}>
                                <Isvg src={rocket} />
                                Novi link
                            </Link>
                        </li>


                    </ul>


                    <ul className="logout">
                        <li onClick={() => localStorage.removeItem('token')}>
                            <Link to='/login' >
                                <Isvg src={exit} />
                                Odjavi se
                            </Link>
                        </li>
                    </ul>

                </div>

                <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                    <Isvg src={menu} />
                </div>

            </div>
        )
    }

};

export default Sidebar;