import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import LoginPage from './views/loginPage';
import PagesListPage from './views/pagesListPage';
import AddPagePage from './views/addPagePage';
import ContactListPage from './views/contactListPage';
import ContactPreviewPage from './views/contactPreviewPage';
import HomePage from './views/homePage';
import AboutUsPage from './views/aboutUsPage';

import CompetitionListPage from './views/competitionListPage';
import CompetitionPreviewPage from './views/competitionPreviewPage';
import SortPage from './views/sortPage';


class Routes extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <Router >
                <div>

                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/aboutus"
                            exact
                            render={(...renderProps) => (
                                <AboutUsPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/projects"
                            exact
                            render={(...renderProps) => (
                                <SortPage {...renderProps} {...this.props} />
                            )}
                        />



                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/collection/:collection"
                            exact
                            render={(...renderProps) => (
                                <PagesListPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/collection/:collection/:id"
                            exact
                            render={(...renderProps) => (
                                <AddPagePage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/competitions"
                            exact
                            render={(...renderProps) => (
                                <CompetitionListPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/competitions/:id"
                            exact
                            render={(...renderProps) => (
                                <CompetitionPreviewPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/contacts"
                            exact
                            render={(...renderProps) => (
                                <ContactListPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/contacts/:id"
                            exact
                            render={(...renderProps) => (
                                <ContactPreviewPage {...renderProps} {...this.props} />
                            )}
                        />





                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;